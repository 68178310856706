import 'bootstrap';

import { dom } from '@fortawesome/fontawesome-svg-core';
dom.watch();


import "./default.fa";
//import "bootstrap/scss/bootstrap.scss";
import "./default.scss";

//$('p').html('<i class="fas fa-star"></i>');